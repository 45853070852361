<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.company_machines") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <el-input
                    :placeholder="$t('message.find')"
                    prefix-icon="el-icon-search"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    size="small"
                    round
                    style="width: 100% !important"
                  ></el-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'transports.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.number.show">
                {{ columns.number.title }}
              </th>

              <th v-if="columns.model.show">
                {{ columns.model.title }}
              </th>

              <th v-if="columns.color.show">
                {{ columns.color.title }}
              </th>

              <th v-if="columns.driver.show">
                {{ columns.driver.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.number.show">
                <crm-input
                  clearable
                  size="mini"
                  :inputValue="filterForm.number"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.number"
                  :placeholder="columns.number.title"
                ></crm-input>
              </th>

              <th v-if="columns.model.show">
                <select-guest-car-model
                  :size="'mini'"
                  :id="filterForm.guest_car_model_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.guest_car_model_id"
                ></select-guest-car-model>
              </th>
              <th v-if="columns.color.show">
                <crm-input
                  clearable
                  size="mini"
                  :inputValue="filterForm.color"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.color"
                  :placeholder="columns.color.title"
                ></crm-input>
              </th>
              <th v-if="columns.driver.show">
                <crm-input
                  clearable
                  size="mini"
                  :inputValue="filterForm.driver"
                  v-model="filterForm.driver"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.driver.title"
                ></crm-input>
              </th>
              <th v-if="columns.name.show">
                <crm-input
                  clearable
                  size="mini"
                  :inputValue="filterForm.name"
                  v-model="filterForm.name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.name.title"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :size="'mini'"
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :size="'mini'"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="tran in list" :key="tran.id" class="cursor-pointer">
              <td v-if="columns.id.show">
                {{ tran.id }}
              </td>
              <td v-if="columns.number.show">
                {{ tran.number }}
              </td>
              <td v-if="columns.model.show">
                {{ tran.model ? tran.model.model : "" }}
              </td>
              <td v-if="columns.color.show">
                {{ tran.color }}
              </td>
              <td v-if="columns.driver.show">
                {{ tran.driver }}
              </td>
              <td v-if="columns.name.show">
                {{ tran.name }}
              </td>
              <td v-if="columns.created_at.show">
                {{ tran.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ tran.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="tran"
                  :actions="actions"
                  :permissionShow="'transports.update'"
                  :permissionDestroy="'transports.destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import crmCreate from "./components/crm-create";
import crmUpdate from "./components/crm-update";
import selectGuestCarModel from "@/components/filters/inventory/select-guest-car-model";
import selectStaff from "@/components/filters/inventory/select-staff";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "sadasdsasdas",
  mixins: [list],
  components: {
    crmCreate,
    crmUpdate,
    selectGuestCarModel,
    selectStaff
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      list: "transport/list",
      columns: "transport/columns",
      pagination: "transport/pagination",
      statues: "transport/statues",
      filter: "transport/filter",
      sort: "transport/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "transport/index",
      setPagination: "transport/setPagination",
      updateSort: "transport/updateSort",
      updateFilter: "transport/updateFilter",
      updateColumn: "transport/updateColumn",
      updatePagination: "transport/updatePagination",
      show: "transport/show",
      empty: "transport/empty",
      delete: "transport/destroy",
      refreshData: "transport/refreshData"
    })
  }
};
</script>
